// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placeLabel
} from '../../../common/edliy_utils';
const Boxes = {
    box1: function () {
    //Create the Board
    JXG.Options.board.minimizeReflow = 'none';
    JXG.Options.point.showInfobox =false;
    JXG.Options.line.highlight=false;
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-16, 11, 10, -15],
    keepaspectratio: true, axis:false, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
   //suspend updates
    //brd1.suspendUpdate();
    // Layering
    brd1.options.layer['image']=11;
    brd1.options.layer['text']=16;
    brd1.options.layer['circle']=10;
    brd1.options.layer['point']=12;
    brd1.options.layer['glider']=12;
    brd1.options.layer['angle']=12;
    brd1.options.layer['line']=11;
    brd1.options.layer['axis']=2;
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeTitle(brd1, 'Phase Difference between Waves', '(Set the Phase Difference. Then click Go!)');
    //dragMe(brd1, 5, 5);
    var j=5;
    var x =-9;
    var y =0;
    placeDash(brd1, [-15, 0], [-5, 0], 1, 'grey');
    placeDash(brd1, [-10, -5], [-10, 5], 1, 'grey');
    var seg = placeCircle(brd1, [-10, 0], [-10, function(){return j}], 2, 'grey');
    var loc =placeGlider(brd1, seg, -10+j, 0, 3, 'white', 'red');
    var plus =placeImage(brd1,'/assets/plus.svg', -9., -9, 2.1, 0);
    var go =placeImage(brd1,'/assets/go.svg', -6., -9, 2, 0);
    placeImage(brd1,'/assets/line.svg', -17.5, -15, 15);
    placeImage(brd1,'/assets/rocks.svg', -11,-15.3,2);
    placeImage(brd1,'/assets/circle.svg', -10.5,-0.5,1);
    //var phase =placeGlider(brd1, seg, -8, 2, 3, 'white', 'black');
    var ang =placeAngle(brd1, [-9, 0], [-10, 0], [()=>loc.X(), ()=>loc.Y()], ()=>0.5*j.valueOf(), 'red');
    ang.setAttribute({name:''});
    var theta=Math.PI/4;
    var phase=placePoint(brd1, ()=>-10+j.valueOf()*Math.cos(ang.Value()+theta.valueOf()), ()=>j.valueOf()*Math.sin(ang.Value()+theta), 3, 'white', 'blue');
    placeDash(brd1, [-10, 0], [()=>-10+j*Math.cos(Math.PI/2+ang.Value()), ()=>j*Math.sin(Math.PI/2+ang.Value())], 1, 'black');
    placeDash(brd1, [-10, 0], [()=>-10+j*Math.cos(3*Math.PI/4+ang.Value()),()=>j*Math.sin(3*Math.PI/4+ang.Value())], 1, 'black')
    placeDash(brd1, [-10, 0], [()=>-10+j*Math.cos(Math.PI+ang.Value()), ()=>j*Math.sin(Math.PI+ang.Value())], 1, 'black')
   placeDash(brd1, [-10, 0], [()=>-10+j*Math.cos(5*Math.PI/4+ang.Value()), ()=>j*Math.sin(5*Math.PI/4+ang.Value())], 1, 'black')
   placeDash(brd1, [-10, 0], [()=>-10+j*Math.cos(7*Math.PI/4+ang.Value()), ()=>j*Math.sin(7*Math.PI/4+ang.Value())], 1, 'black')
   placeDash(brd1, [-10, 0], [()=>-10+j*Math.cos(1*Math.PI/4+ang.Value()), ()=>j*Math.sin(1*Math.PI/4+ang.Value())], 1, 'black')
   placeDash(brd1, [-10, 0], [()=>-10+j*Math.cos(3*Math.PI/2+ang.Value()), ()=>j*Math.sin(3*Math.PI/2+ang.Value())], 1, 'black')
    placeArrow(brd1, [-10, 0], [()=>loc.X(), ()=>loc.Y()], 2,'red');
    placeArrow(brd1, [-10, 0], [()=>phase.X(), ()=>phase.Y()], 2,'blue');
    placeArrow(brd1, [-1, 0], [9, 0], 2,'black');
    placeArrow(brd1, [0, -5], [0, 6], 2,'black');
    var ang1 =placeAngle(brd1, [()=>loc.X(), ()=>loc.Y()], [-10, 0], [()=>phase.X(), ()=>phase.Y()], ()=>0.5*j.valueOf(), 'grey');
    ang1.setAttribute({name:'&phi;'});
    var beta =0;
    var gamma=0;
    var run =function(){
    if(gamma < 2*Math.PI)
    {gamma+=0.025;
     beta =Math.min(gamma, 2*Math.PI);
     x = -10+j.valueOf()*Math.cos(beta);
     y = j.valueOf()*Math.sin(beta)
     }
    loc.moveTo([x,y]);
    if(beta<2*Math.PI){
    setTimeout(run, 0);}
    else {
      return;
    }
    }
    go.on('down', function(){phase.moveTo([-9, 0]); gamma=0; beta=0; run();});
    var minus = placeImage(brd1,'/assets/minuss.svg', -13, -9, 2, 0);
    hoverMe(brd1, plus, 'To Increase Phase Difference', 0, -20);
    hoverMe(brd1, minus, 'To Decrease Phase Difference', 0, -20);
    var horz =placeLine(brd1,[()=>loc.X()-60, ()=>loc.Y()], [()=>loc.X()+60, ()=>loc.Y()], 1, 'grey');
    horz.setAttribute({visible:false});
    //placeRuler(brd1, [6, 0],[6, function(){return loc.Y();}], '5m');
    //placeLeftText(brd1, 6.25, function(){return 0.5*loc.Y()}, function(){return '' + (-loc.Y()).toFixed(2) + ' m'});
    //plus.on('down', function(){if(j<6){j+=0.5;}else{return}});
    //minus.on('down', function(){if(j>2){j-=0.5;}else{return}});
    plus.on('down', function(){loc.moveTo([-5,0]);theta+=Math.PI/4; beta=0;});
    minus.on('down',function(){loc.moveTo([-5,0]);theta-=Math.PI/4;beta=0;});
    //var ang =brd1.create('angle', [[1,-10], [0,-10], loc],{radius:1, center:{visible:false}, radiusPoint:{visible:false}});
    brd1.create('functiongraph',[function(x){return j.valueOf()*Math.sin(x)}, 0, ()=>beta.valueOf()],{strokeColor:'red', strokeWidth:2, highlight:false});
    brd1.create('functiongraph',[function(x){return j.valueOf()*Math.sin(x+theta.valueOf())}, 0, ()=>beta.valueOf()],{strokeColor:'blue', strokeWidth:2, highlight:false});
    var PA =placePoint(brd1, ()=>beta.valueOf(), ()=>loc.Y(), 3, 'white', 'red');
    var PB =placePoint(brd1, ()=>beta.valueOf(), ()=>phase.Y(), 3, 'white', 'blue');
    placeLabel(brd1, PA, 'A', 10, -10);
    placeLabel(brd1, PB, 'B', 10, +10);
    //placeDash(brd1, [()=>ang.Value(), ()=>loc.Y()], [()=>ang.Value(), 0], 1, 'grey');
    //placeDash(brd1, [()=>ang.Value(), ()=>loc.Y()], [0, ()=>loc.Y()], 1, 'grey');
    var txt1 =placeText(brd1, 3, -6.5, function(){return 'Blue wave leads the red wave by &phi; = '+ (Math.abs(theta.valueOf())*180/Math.PI).toFixed(1)+'^o';});
    txt1.setAttribute({visible:()=>theta.valueOf()>0});
//////////////////////
    var txt2 = placeText(brd1, 3, -6.5, function(){return 'Blue wave lags the red wave by &phi; = '+ (Math.abs(theta.valueOf())*180/Math.PI).toFixed(1)+'^o';});
    txt2.setAttribute({visible:()=>theta.valueOf()<0});
    placeText(brd1, 9, -0.5, '<b> &alpha;(rad)</b>');
    placeText(brd1, -0.5, 7, '<b>y</b>');
    placeText(brd1, ()=>(j.valueOf()+0.75)*Math.cos(ang.Value())-10, ()=>(j.valueOf()+0.75)*Math.sin(ang.Value()), 'A');
    placeText(brd1, ()=>(j.valueOf()+0.75)*Math.cos(ang.Value()+theta.valueOf())-10, ()=>(j.valueOf()+0.75)*Math.sin(ang.Value()+theta.valueOf()), 'B');
    brd1.create('text', [3, 7.5, function(){return 'Y_A = R sin(&alpha;)'}],{anchorX:'left', anchorY:'middle', highlight:false, color:'red',CssStyle:'fontFamily:Oswald;',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true, highlight:false});
    brd1.create('text', [3, 6.5, function(){return 'Y_B = R sin(&alpha;+&phi;)'}],{anchorX:'left',anchorY:'middle', highlight:false,color:'blue', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true, highlight:false});
                      },
              }
export default Boxes;

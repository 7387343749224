<template>
  <div>
    <h3 ref="ia" class="mb-2">
      What is a Phase Difference?
    </h3>
    <p>
      Phase difference specifies the relative displacement of two waves or particles that are oscillating or in cyclic motion with same frequency.
      The concept of phase difference is relevant only when there are two or more waves are involved.
      The vertical position of Mo after the wheel has rotated by \(\alpha\) is expressed as:
      $$ y_{Mo} =R_w \sin(\alpha) $$
      Bo's vertical position can be expressed as:
      $$y_{Bo} =R_w \sin(\alpha + \phi) $$
    </p>
    <h3 ref="ja" class="mb-2">
      Leading and Lagging Phase
    </h3>
    <p>
      When the first waveform is ahead of the other waveform, the phase difference is called a 'phase lead'.
      On the other hand, when  the first waveform is behind the other waveform, the phase difference is called a 'phase lag'.
    </p>
    <h3 ref="ja" class="mb-2">
      'In Phase' & 'Out of Phase'
    </h3>
    <p>
      Two waves are said to be 'in phase' when they have zero phase difference.
      When two waves are 'in phase', their maxima occur at the same time.

      Two waves are said to be 'out of phase' when they have 180<sup>o</sup> phase difference. When two waves are 'in phase', their maxima occur at the same time.
    </p>
    <!--<h3 ref="ka">Example: Free Body Diagram of an Inclined Wooden Plank</h3>
<p> In this example, we will consider a massless wooden plank that is resting on a wall. The plank is in contact with two external objects: the floor and the wall.
      To construct the free body diagram of the plank, we will remove these surrounding objects and replace them by the forces exerted by them on the plank.
      </p>
      <v-layout justify-center>
       <h5 style="text-align:center">Figure 1(c): Next, we replace the floor by the force it exerts on the plank. </h5>
      </v-layout> -->
    <h3 ref="pg" class="mb-2">
      MagicGraph | Phase Difference Explained
    </h3>
    <p>
      Bo and Mo are at a carnival. They decide to ride a Ferris wheel.
      Let's observe the vertical motion of Bo and Mo as the wheel spins.
      The wheel ascends to the highest point, and then,
      descends to the lowest point before ascending up again.
      Each of their vertical motion, when traced in time, looks like a sinusoidal curve.

      Now, if they sit in the same cabin, they go through
      the highest point (or the lowest point) at the same time.
      This will be equivalent to saying their motions are in phase.

      However, if they sit in different cabins,
      they will go through the highest point (or the lowest point) at different times.
      This will be equivalent to saying that their motions are out of phase.
    </p>
    <v-responsive>
      <v-layout align-center justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'FBD',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Phase Difference';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Free Body Diagram?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Purpose of FBD', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Example of FBD', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Phase of a Wave',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
